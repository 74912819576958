// Constant
import { RESPONSE_DATA } from "redux/constants";

export const getResponseLoading = () => ({
    type: RESPONSE_DATA.RESPONSE_API_RESULTS_PENDING,
});

export const setScrollLoading = () => ({
    type: RESPONSE_DATA.RESPONSE_API_SCROLL_LOADING,
});

export const getResponseFailure = (error) => ({
    type: RESPONSE_DATA.RESPONSE_API_RESULTS_FAILURE,
    payload: {
        error: error
    }
});

export const getResponseSuccess = (response) => ({
    type: RESPONSE_DATA.RESPONSE_API_RESULTS_SUCCESS,
    payload: {
        response: response
    }
});

export const getRetrievalSuccess = (response) => ({
    type: RESPONSE_DATA.RETRIEVAL_API_RESULTS_SUCCESS,
    payload: {
        response: response
    }
});

export const updateTranscriptInQueryResults = (response) => ({
    type: RESPONSE_DATA.UPDATE_TRANSCRIPT_IN_QUERY_RESULTS,
    payload: {
        response: response
    }
});

export const setFilters = (response) => ({
    type: RESPONSE_DATA.SET_FILTERS,
    payload: {
        response: response
    }
});

export const clearTranscriptSelected = (response) => ({
    type: RESPONSE_DATA.CLEAR_TRANSCRIPT_SELECTED,
    payload: {
        response: response
    }
});

export const chatClearAllMessages = (response) => ({
    type: RESPONSE_DATA.CHAT_CLEAR_ALL_MESSAGES,
    payload: {
        response: response
    }
});

export const chatAddMessage = (response) => ({
    type: RESPONSE_DATA.CHAT_ADD_MESSAGE,
    payload: {
        response: response
    }
});

export const chatSuccess = (response) => ({
    type: RESPONSE_DATA.CHAT_SUCCESS,
    payload: {
        response: response
    }
});

export const setChatHistoryID = (response) => ({
    type: RESPONSE_DATA.CHAT_SET_HISTORY_ID,
    payload: {
        response: response
    }
});

export const updateTranscriptSelected = (response) => ({
    type: RESPONSE_DATA.UPDATE_TRANSCRIPT_SELECTED,
    payload: {
        response: response
    }
});

export const selectTranscript = (response) => ({
    type: RESPONSE_DATA.SELECT_TRANSCRIPT,
    payload: {
        response: response
    }
});

export const setLatestSearchText = (response) => ({
    type: RESPONSE_DATA.SET_LATEST_SEARCH_TEXT,
    payload: {
        response: response
    }
});

export const companyFilterApplied = (response) => ({
    type: RESPONSE_DATA.COMPANY_FILTER_APPLIED,
    payload: {
        response: response
    }
});
export const industryFilterApplied = (response) => ({
    type: RESPONSE_DATA.INDUSTRY_FILTER_APPLIED,
    payload: {
        response: response
    }
});
export const functionFilterApplied = (response) => ({
    type: RESPONSE_DATA.FUNCTION_FILTER_APPLIED,
    payload: {
        response: response
    }
});

export const sourceFilterApplied = (response) => ({
    type: RESPONSE_DATA.SOURCE_FILTER_APPLIED,
    payload: {
        response: response
    }
});

export const setMonthOfInterviewRangeValue = (response) => ({
    type: RESPONSE_DATA.MONTH_OF_INTERVIEW_VALUE,
    payload: {
        response: response
    }
});

export const getFilesDownloadPending = () => ({
    type: RESPONSE_DATA.GET_FILE_PENDING,
});

export const getFileDownloadSuccess = () => ({
    type: RESPONSE_DATA.GET_FILE_SUCCESS
});

export const getFilesDownloadFailure = (error) => ({
    type: RESPONSE_DATA.GET_FILE_FAILURE,
    payload: { error: error?.toString() }
});

export const setBookmarkPending = () => ({
    type: RESPONSE_DATA.SET_BOOKMARK_API_RESULTS_PENDING,
});

export const setBookmarkSuccess = () => ({
    type: RESPONSE_DATA.SET_BOOKMARK_API_RESULTS_SUCCESS
});

export const setBookmarkFailure = (error) => ({
    type: RESPONSE_DATA.SET_BOOKMARK_API_RESULTS_FAILURE,
    payload: { error: error?.toString() }
});

export const getAllBookmarks = () => ({
    type: RESPONSE_DATA.GET_ALL_BOOKMARK
});

export const getAllBookmarksSuccess = (response) => ({
    type: RESPONSE_DATA.GET_ALL_BOOKMARK_API_RESULTS_SUCCESS,
    payload: {
        response: response
    }
});

export const getAllKeywordSearchHistory = () => ({
    type: RESPONSE_DATA.GET_ALL_KEYWORD_SEARCH_HISTORY
});

export const getAllKeywordSearchHistorySuccess = (response) => ({
    type: RESPONSE_DATA.GET_ALL_KEYWORD_SEARCH_HISTORY_API_RESULTS_SUCCESS,
    payload: {
        response: response
    }
});

export const getChatSearchHistory = () => ({
    type: RESPONSE_DATA.GET_CHAT_SEARCH_HISTORY
});

export const getChatSearchHistorySuccess = (response) => ({
    type: RESPONSE_DATA.GET_CHAT_SEARCH_HISTORY_API_RESULTS_SUCCESS,
    payload: {
        response: response
    }
});

export const getSelectedTranscript = (transcriptId) => ({
    type: RESPONSE_DATA.GET_SELECTED_TRANSCRIPT,
    payload: { transcriptId }
});

export const getDocvizRequestIds = (transcriptIds) => ({
    type: RESPONSE_DATA.GET_DOCVIZ_REQUEST_IDS,
    payload: { transcriptIds }
});

export const getDownloadCount = (transcriptId) => ({
    type: RESPONSE_DATA.GET_DOWNLOAD_COUNT,
    payload: { transcriptId }
});

export const getTranscriptDetail = (transcriptId) => ({
    type: RESPONSE_DATA.GET_TRANSCRIPT_DETAIL,
    payload: { transcriptId }
});

export const chatPending = () => ({
    type: RESPONSE_DATA.CHAT_PENDING
});

export const internalTagsAPILoading = () => ({
    type: RESPONSE_DATA.INTERNAL_TAGS_API_RESULTS_PENDING,
});

export const internalTagsAPISuccess = () => ({
    type: RESPONSE_DATA.INTERNAL_TAGS_API_RESULTS_SUCCESS,
});

export const internalTagsAPIFailure = () => ({
    type: RESPONSE_DATA.INTERNAL_TAGS_API_RESULTS_SUCCESS,
});

export const setCurrentPage = (response) => ({
    type: RESPONSE_DATA.SET_CURRENT_PAGE,
    payload: {
        response: response
    }
});

export const setIsLastPage = (response) => ({
    type: RESPONSE_DATA.SET_IS_LAST_PAGE,
    payload: {
        response: response
    }
});