import React, { PureComponent } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import PropTypes from 'prop-types';

import Icon from 'components/shared/Icon';

import './Notification.scss';

export class Notification extends PureComponent {
  toastId = null;
  componentDidUpdate = prevProps => {
    if (
      this.props.timestamp !== prevProps.timestamp ||
      this.props.mode !== 'info'
    ) {
      this.show();
    }
    if (this.props.mode === 'dismissNotifications') {
      toast.dismiss();
    }
  };

  show = () => {
    const { mode, title, message, autoClose } = this.props;

    let iconClass = '';
    switch (mode) {
      case 'error':
        iconClass = 'exclamation triangle';
        break;
      case 'info':
        iconClass = 'info circle';
        break;
      case 'warning':
        iconClass = 'info circle';
        break;
      case 'success':
        iconClass = 'check circle';
        break;
      default:
        // do nothing
        break;
    }

    const msg = () => (
      <>
        <Icon className={iconClass} />
        <div className="title">
          {/* {!!title && <h4>{title}</h4>} */}
          {!!message && <p className="message">{message}</p>}
        </div>
      </>
    );

    const options = {
      autoClose,
      closeOnClick: false
    };

    switch (mode) {
      case 'error':
        toast.error(msg, options);
        break;
      case 'info':
        toast.info(msg, options);
        break;
      case 'warning':
        if (toast.isActive(this.toastId)) {
          toast.update(this.toastId, { ...options, render: msg });
        }
        else {
          this.toastId = toast.warn(msg, options);
        }
        break;
      case 'success':
        toast.success(msg, options);
        break;
      default:
        // do nothing
        break;
    }
  };

  render = () => <ToastContainer
    position="top-center"
    hideProgressBar
    autoClose={3000}
  />;

}

Notification.propTypes = {
  mode: PropTypes.string,
  title: PropTypes.string,
  message: PropTypes.string,
  autoClose: PropTypes.bool,
  timestamp: PropTypes.string
};

Notification.defaultProps = {
  mode: '',
  title: '',
  message: '',
  autoClose: false,
  timestamp: ''
};
