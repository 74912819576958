import React, { useEffect, useRef, useState } from "react";
import Image from "components/shared/Image";
import "./MonthOfInterview.scss";
import { selectors as responseSelector } from "../../redux/reducers/response/response_reducer";
import {
    RESPONSE_DATA, INFO, TAXONOMY
} from "redux/constants";
import { useDispatch, useSelector } from "react-redux";
import { isInViewport, scrollToTop, getMonthYearFromDate } from "utils/helpers/helpers";
import { MonthRangePicker } from "../MonthRangePicker";
import monthOfInterviewIcon from "assets/images/month_of_interview_icon.svg";
import chatInfoIcon from "assets/images/chat_info_icon.svg";
import CrossIconImage from "assets/images/crossiconv1.svg";

// Analytics
import ANALYTICS from "utils/analytics/analytics";
import {
    PAGES,
    PRIMARY_CATEGORY,
    DTMRULE
} from "utils/analytics/analytics_constants";

const {
    SET_FILTERS,
    RETRIEVAL_API_RESULTS_SUCCESS,
    CLEAR_TRANSCRIPT_SELECTED,
    MONTH_OF_INTERVIEW_VALUE,
    QUERY_RESPONSE
} = RESPONSE_DATA;

const {
    RESET_ALL_SL_PARENT_TAGS
} = TAXONOMY;

export const MonthOfInterviewFilter = ({ showMonthOfInterviewFilterContainer, handleFilterIconClick, monthOfInterviewRangeValue, lastUserQues }) => {

    const dispatch = useDispatch();
    const pageURL = window.location.href;
    const filters = useSelector(responseSelector.getFilters);
    const queryResponse = useSelector(responseSelector.getRetrievalResult);
    const transcriptResults = queryResponse ? [...queryResponse] : [];
    const monthOfInterviewRange = filters.monthOfInterviewRange;
    const filterData = filters.filteredIndustryTags || [];
    const filterFunctionTags = filters.filteredFunctionTags || [];
    const companyFilterApplied = useSelector(responseSelector.getCompanyFilterApplied);
    const industryFilterApplied = useSelector(responseSelector.getIndustryFilterApplied);
    const functionFilterApplied = useSelector(responseSelector.getFunctionFilterApplied);
    const enabledMonths = filters.enabledMonths;
    const initialYear = filters.initialYear;
    const sourceFilterApplied = useSelector(responseSelector.getSourceFilterApplied);
    const groupedCompanies = filters.groupedCompanies || [];
    let { startMonth, endMonth } = monthOfInterviewRange;
    const actionButtonContainerElementRef = useRef(null);
    let dateFilterRef = useRef();
    const [notificationMessage, setnotificationMessage] = useState("");

    useEffect(() => {
        if (showMonthOfInterviewFilterContainer && actionButtonContainerElementRef.current) {
            const actionButtonContainer = actionButtonContainerElementRef.current;
            let windowScroll = isInViewport(actionButtonContainer);
            let existingScroll = window.scrollY;
            scrollToTop(existingScroll + windowScroll);
        }
    }, [showMonthOfInterviewFilterContainer]);

    useEffect(() => {
        let filterHandler = (e) => {
            if (!dateFilterRef.current.contains(e.target) && e.target.className !== 'filter date')
                handleFilterIconClick(false)

        }
        document.addEventListener("mousedown", filterHandler);

        return () => {
            document.removeEventListener("mousedown", filterHandler)
        }
    })

    const handleCrossClick = (e) => {
        handleFilterIconClick(false);
    }

    const handleClearClick = (e) => {

        dispatch({
            type: SET_FILTERS, payload: {
                response: {
                    monthOfInterviewRange: {
                        startMonth: null,
                        endMonth: null
                    }
                }
            }
        });
        handleApplyClick("clear");;
    }
    const handleApplyClick = (fromClear) => {
        let value = "";
        if (fromClear !== "clear") {
            if (startMonth === null && endMonth === null) {
                setnotificationMessage("Please select the Start & End Month");
                return;
            } else if (startMonth === null) {
                setnotificationMessage("Please select the Start Month");
                return;
            } else if (endMonth === null) {
                setnotificationMessage("Please select the End Month");
                return;
            } else if (endMonth < startMonth) {
                setnotificationMessage("End Month should be greater than the Start Month");
                return;
            }
            value = getMonthYearFromDate(startMonth) + " - " + getMonthYearFromDate(endMonth || startMonth);
            dispatch({ type: MONTH_OF_INTERVIEW_VALUE, payload: { response: value } });
        } else {
            startMonth = null;
            endMonth = null;
            dispatch({ type: MONTH_OF_INTERVIEW_VALUE, payload: { response: "" } });
        }

        const newAdobeData = {
            page: {
                category: {
                    primaryCategory: PRIMARY_CATEGORY.Transcript_Library,
                },
                pageInfo: {
                    pageName: PAGES.Transcript_Library_Keyword_Search,
                    previousPageName: PAGES.Transcript_Library_Keyword_Search,
                    pageURL: pageURL
                }
            },
            internal: {
                search: { term: lastUserQues }
            }
        };
        newAdobeData.filterCategory = "Month of Interview";
        newAdobeData.filterChecked = "";


        handleFilterIconClick(false);
        dispatch({
            type: QUERY_RESPONSE, payload: {
                question: lastUserQues, resetFilters: true, currentPage: 0, dispatch, selectedFilters: {
                    monthOfInterview: { startMonth, endMonth: endMonth || startMonth },
                    sources: sourceFilterApplied
                }
            }
        });
        dispatch({ type: CLEAR_TRANSCRIPT_SELECTED, payload: { response: "" } });
        dispatch({ type: RESET_ALL_SL_PARENT_TAGS, payload: { response: "" } });


        newAdobeData.filterChecked = value;
        ANALYTICS.tl.sendEventData(newAdobeData, DTMRULE.FILTER_CLICKS);
        //dispatch({ type: RETRIEVAL_API_RESULTS_SUCCESS, payload: { response: transcriptResults } });
        if (companyFilterApplied > 0 || industryFilterApplied > 0 || functionFilterApplied > 0) {
            let notifyMessage = "Existing filters will be cleared: ";
            if (companyFilterApplied > 0) {
                notifyMessage += "Company, ";
            }
            if (industryFilterApplied > 0) {
                notifyMessage += "Industry, ";
            }
            if (functionFilterApplied > 0) {
                notifyMessage += "Function, ";
            }
            // Remove trailing comma and space
            notifyMessage = notifyMessage.slice(0, -2);
            dispatch({ type: INFO, payload: { title: "INFO", message: notifyMessage } });
        }
    }

    return (
        <div ref={dateFilterRef} className="date-filter-container">
            <div className='filter-container'>
                {notificationMessage !== "" &&
                    <div className="notification-container">
                        <div className="notification">
                            <Image src={chatInfoIcon} alt="logo" />
                            <span className="label-text">{notificationMessage}</span>
                        </div>
                    </div>
                }
                <div className={`${notificationMessage !== "" ? "header top-30" : "header"}`}>
                    <Image className="cross-image" src={CrossIconImage} alt="CrossIconImage" onClick={handleCrossClick} />
                </div>
                <div className="month-range-picker-wrapper">
                    <div className="month-range-picker-container">
                        <div className="month-label-container">
                            <div className="month-label">
                                <Image src={monthOfInterviewIcon} alt="logo" />
                                <span className="label-text">Start Month</span>
                            </div>
                        </div>
                        <MonthRangePicker
                            initialYear={initialYear}
                            startMonth={startMonth}
                            endMonth={endMonth}
                            enabledMonths={enabledMonths}
                            monthOfInterviewRangeValue={monthOfInterviewRangeValue}
                            calendarClassName={"month-range-picker-parent"}
                            headerClassName={"month-range-picker-header"}
                            monthClassName={"month-range-picker-cell"}
                            type={'start-month-picker'} />
                    </div>
                    <div className="month-range-picker-container">
                        <div className="month-label-container">
                            <div className="month-label">
                                <Image src={monthOfInterviewIcon} alt="logo" />
                                <span className="label-text">End Month</span>
                            </div>
                        </div>
                        <MonthRangePicker
                            initialYear={initialYear}
                            startMonth={startMonth}
                            endMonth={endMonth}
                            enabledMonths={enabledMonths}
                            monthOfInterviewRangeValue={monthOfInterviewRangeValue}
                            calendarClassName={"month-range-picker-parent"}
                            headerClassName={"month-range-picker-header"}
                            monthClassName={"month-range-picker-cell"}
                            type={'end-month-picker'} />
                    </div>
                </div>
                <div className="filter-separator"></div>
                <div className="filter-action-item" ref={actionButtonContainerElementRef}>
                    <button className="btn secondary button" onClick={handleClearClick}>Clear</button>
                    <button className="btn primary button" onClick={handleApplyClick}>Apply Now</button>
                </div>
            </div>
        </div >

    )
};
