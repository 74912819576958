import React from "react";
import SampleQueryArrow from "assets/images/SampleQueryArrow.svg";
import Image from "components/shared/Image";

import "./SampleQueries.scss";

export const SampleQueries = ({ data, makeApiRequest }) => {
    return (
        <div className="sampleQueriesList">
            <div className="try-searching-text">
                <Image className="sample-query-arrow" src={SampleQueryArrow} alt="SampleQueryArrow" />
                <span>Try searching</span>
            </div>
            {data.map((query, index) => (
                <div key={index} className="sample-query" onClick={() => makeApiRequest(query, undefined, true)}>
                    <div>{query}</div>
                </div>
            ))}
        </div>
    );
};
